import {PageBase} from "../../components/pageBase/pageBase";
import {makeStyles} from "tss-react/mui";
import {Box, Stack, Switch, Button, Typography, LinearProgress, CircularProgress} from "@mui/material";
import {palette} from "../../constants";
import {useContext, useEffect, useState} from "react";
import {INotificationSeverity, NotificationContext, useApi} from "@plumeuk/shapeshift-identity";
import {deepEqual} from "@plumeuk/shapeshift-common/common";
import PersonIcon from "@mui/icons-material/Person";
import {ISettings} from "../../types/settings";
import {LocaleContext} from "../../contexts/localeContext";

const useStyles = makeStyles()((theme) => ({
	pageBase: {
		"& [class*='menuIcon']": {
			display: "none"
		}
	},
	titleContainer: {
		width: "100%",
		padding: "40px 0 0 0"
	},
	title: {
		margin: "0 10px",
		fontWeight: 700,
		fontSize: "62px"
	},
	table: {
		borderCollapse: "collapse",
		width: "100%",
		"td:nth-child(1) *": {
			fontWeight: 600,
			fontSize: "21px"
		},
		"td": {
			padding: "20px",
			borderTop: "1px solid " + palette.grey10

		}
	},
	switch: {
		width: 28,
		height: 16,
		padding: 0,
		display: "flex",
		"&:active": {
			"& .MuiSwitch-thumb": {
				width: 15
			},
			"& .MuiSwitch-switchBase.Mui-checked": {
				transform: "translateX(9px)"
			}
		},
		"& .MuiSwitch-switchBase": {
			padding: 2,
			"&.Mui-checked": {
				transform: "translateX(12px)",
				color: "#fff",
				"& + .MuiSwitch-track": {
					opacity: 1
				}
			}
		},
		"& .MuiSwitch-thumb": {
			width: 12,
			height: 12,
			borderRadius: 6,
			transition: theme.transitions.create(["width"], {
				duration: 200
			})
		},
		"& .MuiSwitch-track": {
			borderRadius: 16 / 2,
			opacity: 1,
			boxSizing: "border-box"
		}
	},
	btnContainer: {
		paddingTop: "20px",
		gap: "10px",
		display: "flex",
		"& button:nth-child(2)": {
			backgroundColor: theme.palette.primary.main
		}
	}
}));

export const SettingsPage: React.FC = () => {
	const {classes} = useStyles();
	const [me] = useApi<ISettings>("/api/user/me")
	const [updateUserResponse, updateUser] = useApi<ISettings>()
	const [formData, setFormData] = useState<ISettings>({notifyEmail: false, notifyInApp: false, preferredLanguage: "en", overDueSkilsReminder: false, dailySkillsReminder: false});
	const {notify} = useContext(NotificationContext)
	const changeMade = !deepEqual(formData, me.data);
	const [,setLocale] = useContext(LocaleContext)

	const redirectToAccountPage = (): void => {
		window.location.href = (process.env.REACT_APP_PROPELAUTH_URL + "/account")
	}

	useEffect(() => {
		if(me.data){
			setFormData(me.data)
		}
	}, [me])

	useEffect(() => {
		if(updateUserResponse.statusCode === 200){
			notify("Your details have been updated", "Success", INotificationSeverity.success, 5000)
			setLocale({locale: updateUserResponse.data?.preferredLanguage})
		}
	}, [updateUserResponse])

	const handleUpdate = (): void => {
		if(updateUserResponse.isLoading || !changeMade)
			return;

		updateUser({
			url: "/api/user",
			data: formData,
			method: "PUT"
		})
	}

	return (
		<PageBase className={classes.pageBase} contentWidth="1260px" disableSideBar>
			<Box className={classes.titleContainer}>
				<Typography className={classes.title}>
					Training Preferences
				</Typography>
			</Box>
			<Box padding={2}/>
			{(!me.data || me.isLoading) ? <LinearProgress /> : <table cellPadding={0} className={classes.table}>
				<tr>
					<td><Typography>General settings</Typography></td>
					<td>
						<Box sx={{display: "flex", gap: "25px"}}>
							<Typography>Preferred language: </Typography>
							<Stack sx={{marginTop: "1px"}} direction="row" spacing={1} alignItems="center">
								<Typography>English</Typography>
								<Switch className={classes.switch} checked={formData.preferredLanguage === "es"} onChange={e => setFormData(prev => ({...prev, preferredLanguage: e.target.checked ? "es" : "en"}))}/>
								<Typography>Spanish</Typography>
							</Stack>
						</Box>
					</td>
				</tr>

				<tr>
					<td style={{border: "none"}}><Typography>Notifications</Typography></td>
					<td>
						<Box sx={{display: "flex", gap: "25px"}}>
							<Stack sx={{marginTop: "1px"}} direction="row" spacing={1} alignItems="center">
								<Switch className={classes.switch} checked={formData.notifyInApp} onChange={e => setFormData(prev => ({...prev, notifyInApp: e.target.checked}))}/>
								<Typography>In-app</Typography>
							</Stack>
						</Box>
					</td>
				</tr>

				<tr>
					<td style={{border: "none"}}></td>
					<td>
						<Box sx={{display: "flex", gap: "25px"}}>
							<Stack sx={{marginTop: "1px"}} direction="row" spacing={1} alignItems="center">
								<Switch className={classes.switch} checked={formData.notifyEmail} onChange={e => setFormData(prev => ({...prev, notifyEmail: e.target.checked}))}/>
								<Typography>Email</Typography>
							</Stack>
						</Box>
					</td>
				</tr>

				<tr>
					<td><Typography>Automatic reminders</Typography></td>
					<td>
						<Box sx={{display: "flex", gap: "25px"}}>
							<Stack sx={{marginTop: "1px"}} direction="row" spacing={1} alignItems="center">
								<Switch className={classes.switch} checked={formData.dailySkillsReminder} onChange={e => setFormData(prev => ({...prev, dailySkillsReminder: e.target.checked}))}/>
								<Typography>Daily skills</Typography>
							</Stack>
						</Box>
					</td>
				</tr>
				<tr>
					<td style={{border: "none"}}></td>
					<td>
						<Box sx={{display: "flex", gap: "25px"}}>
							<Stack sx={{marginTop: "1px"}} direction="row" spacing={1} alignItems="center">
								<Switch className={classes.switch} checked={formData.overDueSkilsReminder} onChange={e => setFormData(prev => ({...prev, overDueSkilsReminder: e.target.checked}))}/>
								<Typography>Overdue skills</Typography>
							</Stack>
						</Box>
					</td>
				</tr>
				<tr>
					<td style={{border: "none"}}></td>
					<td>
						<Box onClick={() => {window.location.href = (process.env.REACT_APP_PROPELAUTH_ACCOUNT_SETTINGS_URI ?? "")}} sx={{display: "flex", gap: "25px", cursor: "pointer"}}>
							<PersonIcon color="primary" />
							<Typography>Manage account details</Typography>
						</Box>
					</td>
				</tr>
				<tr>
					<td style={{border: "none"}}></td>
					<td style={{border: "none"}}>
						<Box className={classes.btnContainer}>
							<Button disabled={!changeMade} sx={{width: "190px"}} onClick={() => handleUpdate()}>
								{updateUserResponse.isLoading ? <CircularProgress /> : "Save preferences"}
							</Button>
							{me.data && <Button disabled={!changeMade} onClick={() => me.data && setFormData(me.data)}>Cancel changes</Button>}
						</Box>
					</td>
				</tr>
			</table>}
			<Box padding={5}></Box>
		</PageBase>
	);
}